import { Service } from '@wix/bookings-uou-types';
import { Preset } from '../../types/types';

export const getAvailableServicesByPreset = ({
  availableServices,
  preset,
  isServiceListDropdownEnabled,
}: {
  availableServices: Service[];
  preset: Preset;
  isServiceListDropdownEnabled: boolean;
}) => {
  return isServiceListDropdownEnabled &&
    availableServices.length &&
    preset === Preset.CALENDAR_PAGE
    ? [availableServices[0]]
    : availableServices;
};
